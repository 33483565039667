import { zodResolver } from '@hookform/resolvers/zod'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { useCallback, useState } from 'react'
import { useForm } from 'react-hook-form'
import { ILoginSchema, LoginSchema } from 'schemas/login.schema'
import { AuthenticationService } from 'services/authentication.service'
import { Field } from './Field'
import { GoogleBtn } from './GoogleBtn'
import {
  Box,
  Button,
  chakra,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Input,
  Text,
  useDisclosure,
  VStack,
} from '@chakra-ui/react'
import KachLogo from './KachLogo'
import CustomLink from './CustomLink'
import { ForgotPasswordModal } from 'kach-components'
import { buildTestId } from 'kach-commons'
import { useError, MicrosoftBtn } from 'kach-components'

export default function Login() {
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm<ILoginSchema>({
    resolver: zodResolver(LoginSchema),
  })

  const [isLoading, setIsLoading] = useState(false)

  const error = useError()

  const onSuccess = useCallback(() => {
    router.push('/')
  }, [])

  const router = useRouter()

  const onLoginLocal = useCallback((data: ILoginSchema) => {
    setIsLoading(true)
    AuthenticationService.loginLocal(data)
      .then(onSuccess)
      .catch(error.handleError)
      .finally(() => setIsLoading(false))
  }, [])

  const onSignInIdentity = useCallback((access_token: string) => {
    setIsLoading(true)
    AuthenticationService.loginIdentity({
      provider: 'google',
      access_token,
    })
      .then(onSuccess)
      .catch(error.handleError)
      .finally(() => setIsLoading(false))
  }, [])

  const { isOpen, onOpen, onClose } = useDisclosure()

  return (
    <>
      <Box
        display='flex'
        minH='full'
        flexDir='column'
        justifyContent='center'
        py={12}
        px={[2, 6, null, 8]}
      >
        <Box display='flex' justifyContent='center'>
          <KachLogo size='5rem' variant='primary' />
        </Box>

        <Box mt={8} mx='auto' w={['full', 'md']}>
          <Box bg='white' py={8} boxShadow='md' borderRadius='md' px={[4, 8]}>
            <chakra.form
              experimental_spaceY={8}
              onSubmit={handleSubmit(onLoginLocal)}
            >
              <FormControl isInvalid={!!errors['user']}>
                <FormLabel>Usuario</FormLabel>
                <Input type='text' {...register('user')} />
                {!!!errors['user'] ? (
                  <FormHelperText></FormHelperText>
                ) : (
                  <FormErrorMessage>{errors['user'].message}</FormErrorMessage>
                )}
              </FormControl>

              <FormControl isInvalid={!!errors['password']}>
                <FormLabel>Contraseña</FormLabel>
                <Input type='password' {...register('password')} />
                {!!!errors['password'] ? (
                  <FormHelperText></FormHelperText>
                ) : (
                  <FormErrorMessage>
                    {errors['password'].message}
                  </FormErrorMessage>
                )}
                <Text
                  {...buildTestId('forgot-password')}
                  onClick={onOpen}
                  cursor='pointer'
                  _hover={{
                    textDecor: 'underline',
                  }}
                >
                  Olvide mi contraseña
                </Text>
              </FormControl>

              <Box>
                <Button
                  {...buildTestId('login-btn')}
                  isLoading={isLoading}
                  type='submit'
                  w='full'
                  colorScheme='brand'
                >
                  Ingresar
                </Button>
              </Box>
            </chakra.form>

            <Box mt={6}>
              <Box position='relative'>
                <Box
                  display='flex'
                  alignItems='center'
                  position='absolute'
                  top={0}
                  bottom={0}
                  left={0}
                  right={0}
                >
                  <Box w='full' borderTop='1px' borderColor='gray.300' />
                </Box>
                <Box
                  position='relative'
                  display='flex'
                  justifyContent='center'
                  fontSize='sm'
                >
                  <chakra.span bg='white' px={2} color='gray.500'>
                    O también puedes
                  </chakra.span>
                </Box>
              </Box>

              <Box mt={4}>
                <VStack spacing={'4'}>
                  <GoogleBtn small onAccept={onSignInIdentity} />

                  <MicrosoftBtn />
                </VStack>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box
        w='full'
        display='flex'
        alignItems='center'
        justifyContent='center'
        experimental_spaceX={2}
      >
        <Text>¿No tienes una cuenta?</Text>
        <CustomLink color='primary' href='/register'>
          Registrate
        </CustomLink>
      </Box>
      <ForgotPasswordModal onOpen={onOpen} isOpen={isOpen} onClose={onClose} />
    </>
  )
}
