import { IUser } from 'kach-clients'
import httpClient from '../http/http-client'
import { ILoginSchema } from '../schemas/login.schema'
import { IRegisterSchema } from '../schemas/register.schema'

export interface ICompanyLink {
  name: string
  url: string
  icon: string
}

export interface ICompany {
  links: ICompanyLink[]
  id: number
  createdAt: string
  updatedAt: string
  deletedAt?: string
  name: string
  ein: string
  domain: string
  type: string
  labs: {
    email: string
    name: string
  }[]
}

export interface IIdentitySignIn {
  provider: 'google'
  access_token: string
}

export interface IIdentityProviderInfo {
  firstName: string
  lastName: string
  profilePhoto: string
  email: string
}

export interface IRegister extends IRegisterSchema {
  strategy: 'identity' | 'local'
}

export class AuthenticationService {
  static async loginIdentity(input: IIdentitySignIn) {
    const response = await httpClient.post('/v1/auth/identity/lab/login', {
      identity: input,
    })

    return response.data
  }

  static async loginLocal(data: ILoginSchema) {
    const response = await httpClient.post('/v1/auth/local/lab/login', data)

    return response.data
  }

  static async register(data: IRegister) {
    const response = await httpClient.post('/v1/auth/lab/register', data)

    return response.data
  }

  static async fetchIdentityProviderInfo(params: {
    provider: 'google'
    access_token: string
  }): Promise<IIdentityProviderInfo> {
    const response = await httpClient.get('/v1/auth/identity-provider', {
      params,
    })

    return response.data
  }

  static async me(cookie?: string): Promise<IUser> {
    const response = await httpClient.get('/v1/auth/me', {
      headers: {
        Cookie: cookie,
      },
    })
    return response.data
  }

  static async logout() {
    const response = await httpClient.post('/v1/auth/lab/logout')

    return response.data
  }
}
