import { z } from 'zod'

export const REQUIRED_FIELD_MESSAGE = 'Campo requerido!'

export const LoginSchema = z.object({
  user: z.string().min(1, REQUIRED_FIELD_MESSAGE),
  password: z.string().min(1, REQUIRED_FIELD_MESSAGE),
})

export interface ILoginSchema extends z.infer<typeof LoginSchema> {}
