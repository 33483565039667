import { Box, Button } from '@chakra-ui/react'
import { Router } from 'next/router'
import { useEffect } from 'react'
import { useGoogle } from '../hooks/useGoogle'
import { GoogleIcon } from './GoogleIcon'

export const GoogleBtn = (props: {
  onAccept: (access_token: string) => void
  isLoading?: boolean
  small?: boolean
}) => {
  const { initGoogleClient, googleClient } = useGoogle(props.onAccept)

  useEffect(() => {
    const script = document.createElement('script')

    script.src = 'https://accounts.google.com/gsi/client'
    script.async = true
    script.defer = true
    script.onload = (e) => initGoogleClient()

    document.body.appendChild(script)

    return () => {
      document.body.removeChild(script)
    }
  }, [Router])

  return (
    <>
      <Box width='full'>
        <Button
          onClick={() =>
            !!googleClient ? googleClient.requestAccessToken() : {}
          }
          bg='white'
          w='full'
          border='1px'
          borderColor='gray.300'
          isLoading={props.isLoading}
          size={props.small ? 'md' : 'lg'}
          _hover={{ bg: 'white' }}
        >
          <Box display='flex' alignItems='center' experimental_spaceX={2}>
            <GoogleIcon
              style={{
                width: !props.small ? '1.6rem' : '1.3rem',
              }}
            />
            <span>Continuar con Google</span>
          </Box>
        </Button>
      </Box>
    </>
  )
}
